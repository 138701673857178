<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap12PackageInitialization"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 12: Package Initialization" image-name="package-init.jpg" image-alt="Package Initialization"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li>How to initialize a package?</li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li>init function</li>
</ul>
<p>Package initialization takes place into <strong>init</strong> functions. In this section, we will see how to use them.</p>
<div id="example-a-mysql-driver" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Example: a MySQL driver <a href="#example-a-mysql-driver"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="what-is-mysql-what-is-a-driver" class="anchor"></div>
<h2 data-number="3.1"><span class="header-section-number">3.1</span> What is MySQL? what is a driver? <a href="#what-is-mysql-what-is-a-driver"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>MySQL is an open-source database management system.</p>
<ul>
<li><p>A database is “a collection of interrelated data stored together in one or more computerized file”<b-link class="citation" data-cites="institute1990ieee" href="#institute1990ieee" >[@institute1990ieee]</b-link></p>
<ul>
<li>It is an organized collection of data stored in a computer system.</li>
</ul></li>
<li><p><strong>SQL</strong> is a query language (Structured Query Language) that MySQL understands</p></li>
<li><p>MySQL is an SQL database. It means that it will understand commands written in SQL.</p></li>
</ul>
<p>MySQL is a program that you can install on a computer. With MySQL, you create a database, inject and retrieve data. To do those actions, you will need to use a program to issue MySQL commands. Those programs are often called “clients”. Those clients will contact the server. The server is the computer where MySQL has been installed and, therefore, where the data is stored.</p>
<ul>
<li><p>MySQL provides a Command Line Interface (CLI) program.</p></li>
<li><p>MySQL workbench offers a graphical interface (GUI)</p></li>
<li><p>Various programming languages have developed modules/libraries to issue commands to MySQL databases. Sometimes those programs are packaged into extensions that we can install easily.</p></li>
</ul>
<p>In the standard library of Go, you can find the package <span v-highlightjs><code class="go" v-pre style="display: inline">database/sql</code></span> that provides a <strong>generic interface around SQL databases</strong>, ie. database systems that can understand SQL.</p>
<p>The standard library does not offer specific programs to speak to a specific database management system. That’s why we need to use a “<strong>driver</strong>” to speak to MySQL.</p>
<p>A MySQL driver is a module built to interact with a MySQL database. In this section, we will study the open-source driver <span v-highlightjs><code class="go" v-pre style="display: inline">github.com/go-sql-driver/mysql</code></span>.</p>
<div id="init-function-from-github.comgo-sql-drivermysql" class="anchor"></div>
<h2 data-number="3.2"><span class="header-section-number">3.2</span> Init function from github.com/go-sql-driver/mysql <a href="#init-function-from-github.comgo-sql-drivermysql"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// https://github.com/go-sql-driver/mysql/blob/master/driver.go
package MySQL

import (
   //...
   &quot;database/sql&quot;
   //...
)
// ...


func init() {
   sql.Register(&quot;mysql&quot;, &amp;MySQLDriver{})
}</code></pre>
<p>The project defines a function named init.</p>
<ul>
<li><p>It has no parameters</p></li>
<li><p>It has no results</p></li>
<li><p>The function calls the exported function <span v-highlightjs><code class="go" v-pre style="display: inline">Register</code></span> from the <span v-highlightjs><code class="go" v-pre style="display: inline">database/sql</code></span> standard library package</p></li>
</ul>
<p>And here is the <span v-highlightjs><code class="go" v-pre style="display: inline">Register</code></span> function :</p>
<pre v-highlightjs><code class="go" v-pre >// Register makes a database driver available by the provided name.
// If Register is called twice with the same name or if driver is nil,
// it panics.
func Register(name string, driver driver.Driver) {
   //...
}</code></pre>
<p>Thanks to the comment, we understand that this function will make the driver available.</p>
<div id="how-to-use-the-driver" class="anchor"></div>
<h2 data-number="3.3"><span class="header-section-number">3.3</span> How to use the driver? <a href="#how-to-use-the-driver"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s take a look at this example program using the driver.</p>
<pre v-highlightjs><code class="go" v-pre >// package-init/init-mysql/main.go
package main

import (
    &quot;database/sql&quot;
    &quot;log&quot;

    _ &quot;github.com/go-sql-driver/mysql&quot;
)

func main() {
    db, err := sql.Open(&quot;mysql&quot;, &quot;user:password@/dbname&quot;)
    if err != nil {
        panic(err)
    }
    log.Println(db)
    //...
}</code></pre>
<ul>
<li><p>In the import block, we have to import statements</p>
<ul>
<li><p>The first one <span v-highlightjs><code class="go" v-pre style="display: inline">"database/sql"</code></span> which imports the standard library package.</p></li>
<li><p>And <span v-highlightjs><code class="go" v-pre style="display: inline">_ "github.com/go-sql-driver/mysql"</code></span> which is a <strong>blank import</strong></p></li>
</ul></li>
<li><p>Then in the main function, we call the function Open from <span v-highlightjs><code class="go" v-pre style="display: inline">"database/sql"</code></span></p>
<ul>
<li>It will open a connection to the database.</li>
</ul></li>
</ul>
<div id="what-about-the-init-function" class="anchor"></div>
<h2 data-number="3.4"><span class="header-section-number">3.4</span> What about the init function <a href="#what-about-the-init-function"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>There is no trace of the init function in our main package... It’s therefore perfectly understandable because :</p>
<ul>
<li><p>The <span v-highlightjs><code class="go" v-pre style="display: inline">init</code></span> function from the driver is unexported.</p></li>
<li><p>Therefore it’s impossible to call it from another package.</p></li>
</ul>
<p>How does Go register the driver if we do not call the init function?</p>
<ul>
<li><p>Our program will call the init function.</p></li>
<li><p><strong>It’s called because we imported the driver with a blank import statement.</strong></p></li>
</ul>
<p>When you import a package with a blank import statement, the package init function will be run.</p>
<p>Note that the author of the driver package could also remove the init function and specifically, instruct the user to call the Register function manually...</p>
<div id="initialization-rules" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Initialization rules <a href="#initialization-rules"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The initialization of a package takes place in a specific order defined by the go specification :</p>
<ol type="1">
<li><p>The imported packages are initialized</p>
<ol type="1">
<li><p>Variables are initialized</p></li>
<li><p>Init functions are run</p></li>
</ol></li>
<li><p>Then the package itself is initialized</p>
<ol type="1">
<li><p>Variables are initialized</p></li>
<li><p>Init functions are run</p></li>
</ol></li>
</ol>
<p>Init functions are run <strong>sequentially</strong>. In other words, init functions are <strong>not</strong> run concurrently.</p>
<div id="rules-illustration" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Rules illustration <a href="#rules-illustration"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="tree-of-the-example-program" class="anchor"></div>
<h2 data-number="5.1"><span class="header-section-number">5.1</span> Tree of the example program <a href="#tree-of-the-example-program"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To illustrate those rules, let’s take an example program. In figure <a href="#fig:Tree-of-our-ex-prog-init-pack" data-reference-type="ref" data-reference="fig:Tree-of-our-ex-prog-init-pack">1</a> you can see the file structure of the program</p>
<figure>
<b-img :src="require('@/assets/images/init_example_program.png')" alt="Tree of our example program[fig:Tree-of-our-ex-prog-init-pack]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Tree of our example program<span id="fig:Tree-of-our-ex-prog-init-pack" label="fig:Tree-of-our-ex-prog-init-pack">[fig:Tree-of-our-ex-prog-init-pack]</span></figcaption>
</figure>
<p>We have three packages: <span v-highlightjs><code class="go" v-pre style="display: inline">room</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">invoice</code></span>, and <span v-highlightjs><code class="go" v-pre style="display: inline">currency</code></span>, along with a main.go file (and a go.mod)</p>
<div id="source-code" class="anchor"></div>
<h2 data-number="5.2"><span class="header-section-number">5.2</span> Source code <a href="#source-code"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Here is the go.mod file :</p>
<pre v-highlightjs><code class="go" v-pre >module maximilien-andile.com/packageInit/rules

go 1.13</code></pre>
<p>And here is the main file :</p>
<pre v-highlightjs><code class="go" v-pre >// package-init/rules-illustration/main.go
package main

import (
    &quot;fmt&quot;

    &quot;maximilien-andile.com/packageInit/rules/invoice&quot;
)

func init() {
    fmt.Println(&quot;main&quot;)
}
func main() {
    fmt.Println(&quot;--program start--&quot;)
    invoice.Print()
}</code></pre>
<p>In the <strong>main</strong> source file, we have added an <span v-highlightjs><code class="go" v-pre style="display: inline">init</code></span> function. Inside the <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> function, we call the invoice package :</p>
<pre v-highlightjs><code class="go" v-pre >// package-init/rules-illustration/invoice/invoice.go
package invoice

import (
    &quot;fmt&quot;

    &quot;maximilien-andile.com/packageInit/rules/currency&quot;
)

func init() {
    fmt.Println(&quot;invoice init&quot;)
}

func Print() {
    fmt.Println(&quot;INVOICE Number X&quot;)
    fmt.Println(54, currency.EuroSymbol())
}</code></pre>
<p>The package <span v-highlightjs><code class="go" v-pre style="display: inline">invoice</code></span> use a function from the <span v-highlightjs><code class="go" v-pre style="display: inline">currency</code></span> package. Here is the source of <span v-highlightjs><code class="go" v-pre style="display: inline">currency</code></span> package :</p>
<pre v-highlightjs><code class="go" v-pre >// package-init/rules-illustration/currency/currency.go
package currency

import &quot;fmt&quot;

var f = func() string {
    fmt.Println(&quot;variable f initialized&quot;)
    return &quot;test&quot;
}()

func init() {
    fmt.Println(&quot;currency init&quot;)
}

func EuroSymbol() string {
    return &quot;EUR&quot;
}</code></pre>
<p>The variable <span v-highlightjs><code class="go" v-pre style="display: inline">f</code></span> seems a little bit strange. We assign to the variable the output result of an anonymous function (see chapter <a href="#chap:Anonymous-Functions-and" data-reference-type="ref" data-reference="chap:Anonymous-Functions-and">[chap:Anonymous-Functions-and]</a>). This function returns the string <span v-highlightjs><code class="go" v-pre style="display: inline">"test"</code></span>. Before returning it displays <span v-highlightjs><code class="go" v-pre style="display: inline">"variable f initialized"</code></span>.</p>
<p>This function demonstrates when variables are initialized. They call <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println</code></span>, so we will detect initialization in the program output.</p>
<p>Finally, this is the source file of the <span v-highlightjs><code class="go" v-pre style="display: inline">room</code></span> package :</p>
<pre v-highlightjs><code class="go" v-pre >// package-init/rules-illustration/room/room.go
package room

import &quot;fmt&quot;

func init() {
    fmt.Println(&quot;room init&quot;)
}</code></pre>
<p>We can observe that the expected behavior in the output result of our program. First, the imported packages are initialized then the main package is initialized.</p>
<div id="compilation-and-run" class="anchor"></div>
<h2 data-number="5.3"><span class="header-section-number">5.3</span> Compilation and run <a href="#compilation-and-run"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go
./main
variable f initialized
currency init
invoice init
main
--program start--
INVOICE Number X
54 EUR</code></pre>
<div id="result-analysis" class="anchor"></div>
<h2 data-number="5.4"><span class="header-section-number">5.4</span> Result analysis <a href="#result-analysis"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<figure>
<b-img :src="require('@/assets/images/init.png')" alt="Initialization steps of a package"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Initialization steps of a package</figcaption>
</figure>
<p>The sequence of a package initialization is always the same :</p>
<ol type="1">
<li><p>Variables are initialized</p></li>
<li><p>Then the init functions are run</p></li>
</ol>
<p>What we can note in the log is that packages are initialized in a specific order (see figure <a href="#fig:Initialization-sequence-multi-pack" data-reference-type="ref" data-reference="fig:Initialization-sequence-multi-pack">2</a>)</p>
<figure>
<b-img :src="require('@/assets/images/init_multipackages.png')" alt="Initialization sequence of multiple packages[fig:Initialization-sequence-multi-pack]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Initialization sequence of multiple packages<span id="fig:Initialization-sequence-multi-pack" label="fig:Initialization-sequence-multi-pack">[fig:Initialization-sequence-multi-pack]</span></figcaption>
</figure>
<p>This initialization sequence is following the dependencies of our main package. It begins with the package at the end of the dependency graph, which is the package <span v-highlightjs><code class="go" v-pre style="display: inline">currency</code></span> (see figure <a href="#fig:Dependency-graph-for-package-init" data-reference-type="ref" data-reference="fig:Dependency-graph-for-package-init">3</a>.</p>
<figure>
<b-img :src="require('@/assets/images/packageDeps.png')" alt="Dependency graph for the project.[fig:Dependency-graph-for-package-init]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Dependency graph for the project.<span id="fig:Dependency-graph-for-package-init" label="fig:Dependency-graph-for-package-init">[fig:Dependency-graph-for-package-init]</span></figcaption>
</figure>
<div id="can-we-define-several-init-functions" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Can we define several init functions? <a href="#can-we-define-several-init-functions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Absolutely! A package can define more than one init function. The runtime will launch init functions sequentially.</p>
<div id="order-of-variables-initialization-advanced" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Order of variables initialization (advanced) <a href="#order-of-variables-initialization-advanced"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Let’s see how to determine which variable will be initialized first. Here is a package with three variables and an init function :</p>
<pre v-highlightjs><code class="go" v-pre >// package-init/rules-illustration-advanced/invoice/invoice.go
package invoice

import (
    &quot;fmt&quot;
)

var c = func() string {
    fmt.Println(&quot;variable c initialized&quot;, b)
    return &quot;value of c&quot;
}()

var a = func() string {
    fmt.Println(&quot;variable a initialized&quot;)
    return &quot;value of a&quot;
}()

var b = func() string {
    fmt.Println(&quot;variable b initialized&quot;, a)
    return &quot;value of b&quot;
}()

func init() {
    fmt.Println(&quot;invoice init&quot;, c)
}

func Print() {
    fmt.Println(&quot;INVOICE Number X&quot;)
}</code></pre>
<p>And here is our main function :</p>
<pre v-highlightjs><code class="go" v-pre >// package-init/rules-illustration-advanced/main.go
package main

import (
    &quot;fmt&quot;

    &quot;maximilien-andile.com/packageInit/rules2/invoice&quot;
)

func init() {
    fmt.Println(&quot;main&quot;)
}
func main() {
    fmt.Println(&quot;--program start--&quot;)
    invoice.Print()
}</code></pre>
<p>After compilation, we can run the program :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go
$ ./main
variable first initialized
variable second initialized value of first
variable third initialized value of first
invoice init value of third
main
--program start--
INVOICE Number X</code></pre>
<p>We note that the variables are initialized in the order :</p>
<ol type="1">
<li><p>a</p></li>
<li><p>b</p></li>
<li><p>c</p></li>
</ol>
<p>The first variable initialized does not depend on anything (except package fmt). It is the first to be initialized. Then the variable <span v-highlightjs><code class="go" v-pre style="display: inline">b</code></span> (which depends on variable a) is initialized. When <span v-highlightjs><code class="go" v-pre style="display: inline">b</code></span> is initialized, we can initialize <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span>.</p>
<p>Variables are not initialized from top to bottom: here, <span v-highlightjs><code class="go" v-pre style="display: inline">a</code></span> is initialized first, but it is put in the second position in the source file.</p>
<p>When the runtime launches the initialization process for a package it will proceed by “cycle”. Each variable has an attribute “ready for initialization”. A variable is considered to be “ready” for initialization when :</p>
<ul>
<li><p>It is not yet initialized</p></li>
<li><p>It has no initialization expression <strong>OR</strong> Its initialization expression has no dependencies on uninitialized variables.</p></li>
</ul>
<ol type="1">
<li><p>During the first cycle, the runtime will select: the earliest variable in declaration order <strong>AND</strong> ready for initialization.</p></li>
<li><p>When the first cycle is completed, the second cycle is launched: Go will select the variable that is earliest in declaration order <strong>AND</strong> ready for initialization.</p></li>
<li><p>The third cycle will do the same.</p></li>
<li><p>The fourth will also do the same...</p></li>
<li><p>etc...</p></li>
</ol>
<p>This process ends when there is no more variable to initialize. You can vizualize on figure <a href="#fig:Variable-initialization-algorith" data-reference-type="ref" data-reference="fig:Variable-initialization-algorith">4</a> the algorithm used :</p>
<figure>
<b-img :src="require('@/assets/images/var_initialization_cycle.png')" alt="Variable initialization algorithm[fig:Variable-initialization-algorith]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Variable initialization algorithm<span id="fig:Variable-initialization-algorith" label="fig:Variable-initialization-algorith">[fig:Variable-initialization-algorith]</span></figcaption>
</figure>
<p>As shown on figure <a href="#fig:Variable-initialization-algorith" data-reference-type="ref" data-reference="fig:Variable-initialization-algorith">4</a> some variables may still be uninitialized. In that case, the program is invalid.</p>
<div id="test-yourself" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the name of the function that will initialize a package? How many parameters this function have? How many results?</p></li>
<li><p>Initialization functions are mandatory in each package. True or False?</p></li>
<li><p>Is it possible to have four initialization functions in a package?</p></li>
<li><p>Initialization functions are called before variables initialization. True or False?</p></li>
<li><p>The runtime can run several initialization functions potentially at the same time. True or False?</p></li>
<li><p>If package A imports package B, which initialization functions are run first ?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="8.2"><span class="header-section-number">8.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the name of the function that will initialize a package? How many parameters this function have? How many results?</p>
<ol type="1">
<li>Here is an example (empty init function) :</li>
</ol>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >func init() {
}</code></pre>
<ol type="1">
<li>No results, no parameters!</li>
</ol></li>
<li><p>Initialization functions are mandatory in each package. True or False?</p>
<ol type="1">
<li><p>False</p></li>
<li><p>Init functions are optional</p></li>
</ol></li>
<li><p>Is it possible to have four initialization functions in a package?</p>
<ol type="1">
<li>Yes</li>
</ol></li>
<li><p>Initialization functions are called before variables initialization. True or False?</p>
<ol type="1">
<li><p>False.</p></li>
<li><p>Init functions are called after variable initialization.</p></li>
</ol></li>
<li><p>The runtime can run several initialization functions potentially at the same time. True or False?</p>
<ol type="1">
<li>False they are run sequentially (one after another)</li>
</ol></li>
<li><p>If package A imports package B, which initialization functions are run first ?</p>
<ol type="1">
<li>The runtime will run init functions from package B before those of package A</li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Initialization of packages takes place in <span v-highlightjs><code class="go" v-pre style="display: inline">init</code></span> functions.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">init</code></span> functions are not mandatory.</p></li>
<li><p>A package can declare several <span v-highlightjs><code class="go" v-pre style="display: inline">init</code></span> functions.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">init</code></span> functions are called after variable initialization.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">init</code></span> functions are run sequentially.</p></li>
</ul>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap12PackageInitialization"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap11PackagesAndImports'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Packages and imports</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap13Types'}">
									<p><u><small>Next</small></u></p>
									<p><small>Types</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Package Initialization - Practical Go Lessons"
const description = "Package initialization takes place into init functions. In this section, we will see how to use them."

export default {
  name: "Chap12PackageInitialization",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
